<template>
  <div class="admin appointments">

    <header class="admin-header cf">
      <h1>Leistungen</h1>
      <div class="meta">
        <a @click="modal_add = true" class="button button-red">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor" d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"></path>
          </svg>
          <span>Leistung hinzufügen</span>
        </a>
      </div>
    </header>

    <div v-if="loading" class="loading-wrap">
      <span class="loading-spinner"></span>
    </div>
    <div v-else>

      <div class="row row-gutter-20">
        <div class="col-12">
          <div class="search-wrap" style="margin-bottom:10px">
            <input v-model="search_phrase" v-on:input="search_handler" type="text" class="form-search" style="width:81%">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>
        </div>
        <div class="col-12">
          <div style="text-align:right;padding:8px 0 0 0">
            <ul class="nav nav-tabs">
              <li v-if="locations.length > 1" key="" :class="check_location_class('')">
                <a @click="select_location_class('')">Alle Standorte</a>
              </li>
              <li v-for="location in locations" v-bind:key="location.id" :class="check_location_class(location.id)" style="margin-left:7px">
                <a @click="select_location_class(location.id)">{{ location.name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div v-if="search_loading" class="loading-wrap">
        <span class="loading-spinner"></span>
      </div>
      <div v-else>

        <div v-if="services.length == 0" class="alert alert-info">
          <p>Aktuell sind noch keine Leistungen vorhanden.</p>
        </div>
        <div v-else>
          <table class="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Kategorie</th>
                <th>Standort</th>
                <th>Preis</th>
                <th>Dauer</th>
                <th>Status</th>
                <th>Schalter</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="service in services" v-bind:key="service.id">
                <td width="20%">
                  <span v-if="service.status == 'deleted'" style="text-decoration: line-through;">{{ service.name }}</span>
                  <span v-else>{{ service.name }}</span>
                </td>
                <td width="15%">
                  <span v-if="service.category">
                    <span v-if="service.status == 'deleted'" style="text-decoration: line-through;">{{ service.category.name }}</span>
                    <span v-else>{{ service.category.name }}</span>
                  </span>
                </td>
                <td width="15%">
                  <span v-if="service.category">
                    <span v-if="service.status == 'deleted'" style="text-decoration: line-through;">{{ service.category.location_name }}</span>
                    <span v-else>{{ service.category.location_name }}</span>
                  </span>
                </td>
                <td width="10%">
                  <span v-if="service.status == 'deleted'" style="text-decoration: line-through;">{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'eur', }).format(service.price) }}</span>
                  <span v-else>{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'eur', }).format(service.price) }}</span>
                </td>
                <td width="10%">
                  <span v-if="service.status == 'deleted'" style="text-decoration: line-through;">{{ service.duration }}min</span>
                  <span v-else>{{ service.duration }}min</span>
                </td>
                <td width="15%">
                  <span v-if="service.status == 'hidden'" class="status-hidden">
                    <span class="status-icon"></span>
                    <span class="status-text">Ausgeblendet</span>
                  </span>
                  <span v-if="service.status == 'visible'" class="status-visible">
                    <span class="status-icon"></span>
                    <span class="status-text">Sichtbar</span>
                  </span>
                  <span v-if="service.status == 'deleted'" class="status-deleted">
                    <span class="status-icon"></span>
                    <span class="status-text">Gelöscht</span>
                  </span>
                </td>
                <td width="15%">
                  <span v-for="name in service.counters" v-bind:key="name" style="display:inline-block;margin-right:10px;background: #F6F6F6;border: 1px solid #D0D0D0;border-radius: 4px;font-size:12px;padding:5px 8px">{{ name.name }}</span>
                </td>
                <td width="10%" class="actions">
                  <a @click="edit_service(service)">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>
                  </a>
                  <!--<a @click="delete_service(service)">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </a>-->
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_add">
      <svg @click="modal_add = false" aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle" class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
      </svg>
      <div class="modal-head">
        <h3>Leistung hinzufügen</h3>
      </div>
      <div class="modal-body">

        <div class="form-wrap select-wrap">
          <label for="status">Status</label>
          <select v-model="service.status" class="form-input">
            <option key="hidden" value="hidden">Ausgeblendet</option>
            <option key="visible" value="visible">Sichtbar</option>
            <option key="deleted" value="deleted">Gelöscht</option>
          </select>
        </div>

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="name" class="form-label">Name</label>
              <input v-model="service.name" class="form-input input-grey" type="text" name="name" id="name">
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="price" class="form-label">Preis</label>
              <currency-input v-model="service.price" :options="{ currency: 'EUR', locale: 'de-DE', hideCurrencySymbolOnFocus: false }" class="form-input" />
            </div>
          </div>
        </div>

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="duration" class="form-label">Dauer (in min)</label>
              <input v-model="service.duration" class="form-input input-grey" type="text" name="duration" id="duration">
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap select-wrap">
              <label for="category_id">Kategorie</label>
              <select v-model="service.category_id" class="form-input">
                <option v-for="category in categories" v-bind:key="category.id" v-bind:value="category.id">{{ category.name }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="form-wrap">
          <label for="web_url" class="form-label">Link zur Webseite</label>
          <input v-model="service.web_url" class="form-input" type="text" name="web_url" id="web_url">
        </div>

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="cost_center" class="form-label">Kostenstelle</label>
              <input v-model="service.cost_center" class="form-input input-grey" type="text" name="cost_center" id="cost_center">
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="gl_account" class="form-label">Sachkonto</label>
              <input v-model="service.gl_account" class="form-input input-grey" type="text" name="gl_account" id="gl_account">
            </div>
          </div>
        </div>

        <div class="form-wrap">
          <label for="description">Beschreibung</label>
          <textarea v-model="service.description" id="description" name="description" rows="2" class="form-input"></textarea>
        </div>

        <div class="form-wrap">
          <label for="name" class="form-label">Schalter</label>
          <select v-model="service.counters" multiple  class="form-input">
            <option v-for="counter in counters" v-bind:key="counter.id" v-bind:value="counter.id">{{ counter.name }}</option>
          </select>
        </div>

        <div class="form-wrap" style="margin: 20px 0 10px 0">
          <input v-model="service.multi_select_possible" type="checkbox" id="multi_select_possible" style="display:inline-block;vertical-align:middle;margin-right:5px">
          <label for="multi_select_possible" style="display:inline-block;vertical-align:middle;margin:0">Soll diese Leistung mehrfach ausgewählt werden dürfen?</label>
        </div>

      </div>
      <div class="modal-meta">
        <a @click="create_service" class="button button-red button-100">Leistung erstellen ›</a>
      </div>
    </vue-final-modal>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_edit">
      <svg @click="modal_edit = false" aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle" class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
      </svg>
      <div class="modal-head">
        <h3>Leistung bearbeiten</h3>
      </div>
      <div class="modal-body">

        <div class="form-wrap select-wrap">
          <label for="status">Status</label>
          <select v-model="selected_service.status" class="form-input">
            <option key="hidden" value="hidden">Ausgeblendet</option>
            <option key="visible" value="visible">Sichtbar</option>
            <option key="deleted" value="deleted">Gelöscht</option>
          </select>
        </div>

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="name" class="form-label">Name</label>
              <input v-model="selected_service.name" class="form-input input-grey" type="text" name="name" id="name">
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="price" class="form-label">Preis</label>
              <currency-input v-model="selected_service.price" :options="{ currency: 'EUR', locale: 'de-DE', hideCurrencySymbolOnFocus: false }" class="form-input" />
            </div>
          </div>
        </div>

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="duration" class="form-label">Dauer (in min)</label>
              <input v-model="selected_service.duration" class="form-input input-grey" type="text" name="duration" id="duration">
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap select-wrap">
              <label for="category_id">Kategorie</label>
              <select v-model="selected_service.category_id" class="form-input">
                <option v-for="category in categories" v-bind:key="category.id" v-bind:value="category.id">{{ category.name }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="form-wrap">
          <label for="web_url" class="form-label">Link zur Webseite</label>
          <input v-model="selected_service.web_url" class="form-input" type="text" name="web_url" id="web_url">
        </div>

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="cost_center" class="form-label">Kostenstelle</label>
              <input v-model="selected_service.cost_center" class="form-input input-grey" type="text" name="cost_center" id="cost_center">
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="gl_account" class="form-label">Sachkonto</label>
              <input v-model="selected_service.gl_account" class="form-input input-grey" type="text" name="gl_account" id="gl_account">
            </div>
          </div>
        </div>

        <div class="form-wrap">
          <label for="slot_amount" class="form-label">Wie viele Termine sollen parallel hinterlegt werden können?<small style="display:block;opacity:0.5;margin-top:3px">(Wenn dieses Feld leer ist, greift die Option beim Standort)</small></label>
          <input v-model="selected_service.slot_amount" class="form-input" type="text" name="slot_amount" id="slot_amount">
        </div>

        <div class="form-wrap">
          <label for="description">Beschreibung</label>
          <textarea v-model="selected_service.description" id="description" name="description" rows="2" class="form-input"></textarea>
        </div>

        <div class="form-wrap">
          <label for="name" class="form-label">Schalter</label>
          <select v-model="selected_service.counters" multiple  class="form-input">
            <option v-for="counter in counters" v-bind:key="counter.id" v-bind:value="counter.id">{{ counter.name }}</option>
          </select>
        </div>

        <div class="form-wrap" style="margin: 20px 0 10px 0">
          <input v-model="selected_service.multi_select_possible" type="checkbox" id="multi_select_possible2" name="multi_select_possible2" style="display:inline-block;vertical-align:middle;margin-right:5px">
          <label for="multi_select_possible2" style="display:inline-block;vertical-align:middle;margin:0">Soll diese Leistung mehrfach ausgewählt werden dürfen?</label>
        </div>

      </div>
      <div class="modal-meta">
        <a @click="update_service" class="button button-red button-100">Leistung bearbeiten ›</a>
      </div>
    </vue-final-modal>

  </div>
</template>

<script>
import CurrencyInput from '@/components/CurrencyInput'

export default {
  name: 'admin_categories',
  components: { CurrencyInput },
  data () {
    return {
      loading: true,
      modal_add: false,
      modal_edit: false,
      services: [],
      counters: [],
      service: {},
      selected_service: {},
      search_loading: false,
      search_phrase: "",
      searching: false,
      class_location: "",
    }
  },
  methods: {
    async get_data() {
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/locations', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.locations = response.data.locations;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/services', {
        params: {
          all: true,
          location_id: this.class_location,
        },
        headers: { Authorization: this.$store.getters.get_token }
      })
      .then(response => {
        this.services = response.data.services;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/categories', { params: { all: true }, headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.categories = response.data.categories;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/counters', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.counters = response.data.counters;
      })
      this.loading = false;
      this.search_loading = false;
    },
    delete_service(service) {
      if(confirm("Wirklich löschen?")) {
        this.$http.delete(process.env.VUE_APP_BASE_API+'/admin/services/'+service.id, { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.get_data();
          this.$notify({
            title: "Erfolgreich gelöscht.",
            type: "success"
          });
        })
      }
    },
    edit_service(service) {
      this.selected_service = service;
      this.modal_edit = true;
    },
    create_service() {
      this.$http.post(process.env.VUE_APP_BASE_API+'/admin/services', this.service, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.modal_add = false;
        this.service = {};
        this.$notify({
          title: "Erfolgreich erstellt.",
          type: "success"
        });
      })
      .catch(error => {
        error.response.data.errors.forEach(value => {
          this.$notify({
            title: value,
            type: "error"
          });
        });
      });
    },
    update_service() {
      this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/services/'+this.selected_service.id, this.selected_service, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.modal_edit = false;
        this.$notify({
          title: "Erfolgreich gespeichert.",
          type: "success"
        });
      })
      .catch(error => {
        error.response.data.errors.forEach(value => {
          this.$notify({
            title: value,
            type: "error"
          });
        });
      });
    },
    search_handler() {
      if (this.searching == false) {
        this.search_loading = true;
        this.searching = true;
        this.$http.get(process.env.VUE_APP_BASE_API+'/admin/services', {
          params: {
            location_id: this.class_location,
            search_phrase: this.search_phrase
          },
          headers: { Authorization: this.$store.getters.get_token }
        })
        .then(response => {
          this.services = response.data.services;
          this.search_loading = false;
          this.searching = false;
        })
      }
    },
    check_location_class(location) {
      if (location == this.class_location) {
        return 'active';
      } else {
        return '';
      }
    },
    select_location_class(location) {
      this.search_loading = true;
      this.class_location = location;
      this.get_data();
    },
  },
  mounted () {
    this.get_data();
  }
}
</script>
